import { DownloadOutlined } from '@ant-design/icons'
import { Card } from 'antd'
import axios from 'axios'
import JSZip from 'jszip'
import { saveAs } from 'file-saver'
import { handleGoogleAPIError } from '../utils/errorHandlers'
import { useContext } from 'react'
import { AuthContext } from '../contexts/AuthContext'

const Class = ({ course, accessToken }) => {
  const { setIsAuthenticated } = useContext(AuthContext)

  const getAllMaterials = () => {
    try {
      return axios
        .get(
          `https://classroom.googleapis.com/v1/courses/${course.id}/announcements`,
          {
            headers: { Authorization: `Bearer ${accessToken}` },
          }
        )
        .then((res) => {
          return res.data.announcements
            ? res.data.announcements
                .map((announcement) => {
                  return (
                    announcement.materials &&
                    announcement.materials.map((material) => {
                      return (
                        material.driveFile && {
                          id: material.driveFile.driveFile.id,
                          title: material.driveFile.driveFile.title,
                        }
                      )
                    })
                  )
                })
                .flat()
                .filter((material) => material && material)
            : []
        })
    } catch (err) {
      console.error(err)
      if (err.response && err.response.status === 403) {
        handleGoogleAPIError(err, setIsAuthenticated)
      } else {
        alert('Something went wrong. Please try again later.')
      }
    }
  }

  const downloadMultipleMaterials = async (materials) => {
    try {
      const zip = new JSZip()
      const zipFile = materials.map(async (material) => {
        const res = await axios.get(
          `https://www.googleapis.com/drive/v3/files/${material.id}?alt=media`,
          {
            headers: { Authorization: `Bearer ${accessToken}` },
            responseType: 'arraybuffer',
          }
        )
        const data = new Blob([res.data])
        zip.file(`${material.title}`, data)

        return data
      })

      await Promise.all(zipFile)
      zip.generateAsync({ type: 'blob' }).then((content) => {
        saveAs(content, `${course.name}.zip`)
      })
    } catch (err) {
      console.error(err)
      if (err.response && err.response.status === 403) {
        handleGoogleAPIError(err, setIsAuthenticated)
      } else {
        alert('Something went wrong. Please try again later.')
      }
    }
  }

  const handleDownload = async () => {
    let marterials = await getAllMaterials()
    downloadMultipleMaterials(marterials)
  }

  return (
    <Card
      key={course.id}
      style={{
        width: 240,
        position: 'relative',
        borderColor: '#3d98ff',
        backgroundColor: '#f0f0f0',
      }}
    >
      <h3 className='two-lines'>{course.name}</h3>
      <DownloadOutlined
        style={{
          position: 'absolute',
          bottom: '15px',
          right: '15px',
          fontSize: '20px',
          color: '#3d98ff',
          cursor: 'pointer',
        }}
        onClick={handleDownload}
      />
    </Card>
  )
}

export default Class
