import request from './request'

export const handleGoogleAPIError = async (err, setIsAuthenticated) => {
  console.error(err)
  if (err.response && err.response.status === 403) {
    try {
      await request.post(
        '/auth/logout',
        {},
        {
          withCredentials: true,
        }
      )
      alert(
        'Insufficient permissions. Please login again and provide required permissions.'
      )
      setIsAuthenticated(false)
    } catch (err) {
      console.error(err)
    }
  } else {
    alert('Something went wrong. Please try again later.')
  }
}
