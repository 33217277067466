import { useEffect, useState } from 'react'
import './App.css'
import request from './utils/request'
import GoogleClassroom from './components/GoogleClassroom'
import { Button, Flex } from 'antd'
import config from './config.json'
import { AuthContext } from './contexts/AuthContext'

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(null)
  const [accessToken, setAccessToken] = useState(null)

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const res = await request.get('/auth/getToken', {
          withCredentials: true,
        })
        setIsAuthenticated(true)
        setAccessToken(res.data.accessToken)
      } catch (err) {
        setIsAuthenticated(false)
      }
    }
    checkAuth()
  }, [])

  const login = () => {
    window.location.href = config.BACKEND_BASE_URL + '/auth/google'
  }

  const logout = async () => {
    try {
      await request.post(
        '/auth/logout',
        {},
        {
          withCredentials: true,
        }
      )
      setIsAuthenticated(false)
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <div className='App'>
      <header
        style={{
          height: '52px',
          padding: '10px 20px',
          position: 'fixed',
          width: '100%',
          zIndex: 1000,
          top: 0,
          backgroundColor: 'white',
          boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
          boxSizing: 'border-box',
        }}
      >
        <Flex justify='space-between'>
          <span style={{ fontSize: '20px', margin: 0 }}>GCR Materials</span>
          {(() => {
            if (isAuthenticated === true)
              return <Button onClick={logout}>Logout</Button>
            if (isAuthenticated === false)
              return <Button onClick={login}>Login</Button>
          })()}
        </Flex>
      </header>
      <div style={{ flexGrow: 1 }}>
        {isAuthenticated === true ? (
          <div style={{ paddingTop: '52px' }}>
            <AuthContext.Provider
              value={{
                setIsAuthenticated,
                accessToken,
              }}
            >
              <GoogleClassroom />
            </AuthContext.Provider>
          </div>
        ) : (
          isAuthenticated === false && (
            <div style={{ flexGrow: 1 }}>
              <Flex
                style={{
                  padding: '10px',
                  boxSizing: 'border-box',
                  justifyContent: 'center',
                  alignItems: 'center',
                  textAlign: 'center',
                  position: 'absolute',
                  width: '100%',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                }}
              >
                <div>
                  <h1>Welcome to GCR Materials</h1>
                  <p>
                    This is a simple website that allows you to download all the
                    materials from your Google Classroom courses.
                  </p>
                  <p>
                    To get started, please click the "Login" button at the top
                    right corner of the page.
                  </p>
                </div>
              </Flex>
            </div>
          )
        )}
      </div>
      <footer
        style={{
          position: 'sticky',
          padding: '20px',
          boxSizing: 'border-box',
          display: 'flex',
          justifyContent: 'center',
          zIndex: 1000,
          backgroundColor: 'white',
          boxShadow: '0 -2px 8px rgba(0, 0, 0, 0.1)',
        }}
      >
        <div>
          <span>
            Made with ❤️ by{' '}
            <a
              rel='noreferrer'
              target='_blank'
              href='https://kunaljain.dev/'
              style={{ color: '#3d98ff', cursor: 'pointer' }}
            >
              Kunal Jain
            </a>
          </span>
        </div>
      </footer>
    </div>
  )
}

export default App
