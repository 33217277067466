import { useState, useEffect, useContext } from 'react'
import { Flex, Spin } from 'antd'
import Class from './Class'
import axios from 'axios'
import Search from 'antd/es/transfer/search'
import { handleGoogleAPIError } from '../utils/errorHandlers'
import { AuthContext } from '../contexts/AuthContext'

const GoogleClassroom = () => {
  const { accessToken, setIsAuthenticated } = useContext(AuthContext)
  const [courses, setCourses] = useState(null)
  const [filteredCourses, setFilteredCourses] = useState([])
  const [search, setSearch] = useState('')

  useEffect(() => {
    axios
      .get('https://classroom.googleapis.com/v1/courses', {
        headers: { Authorization: `Bearer ${accessToken}` },
      })
      .then((res) => {
        setCourses(
          res.data.courses
            ? res.data.courses.sort((a, b) => {
                return a.name > b.name ? 1 : a.name < b.name ? -1 : 0
              })
            : []
        )
        setFilteredCourses(
          res.data.courses
            ? res.data.courses.sort((a, b) => {
                return a.name > b.name ? 1 : a.name < b.name ? -1 : 0
              })
            : []
        )
        setSearch('')
      })
      .catch((err) => {
        handleGoogleAPIError(err, setIsAuthenticated)
      })
  }, [accessToken, setIsAuthenticated])

  if (courses === null) {
    return (
      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <center>
          <Spin size='large' />
          <p style={{ color: '#3d98ff' }}>Loading your courses</p>
        </center>
      </div>
    )
  }

  return (
    <div style={{ marginBottom: '20px' }}>
      {!!courses.length && (
        <div className='search'>
          <Search
            placeholder='Search Courses'
            value={search}
            onChange={(e) => {
              setSearch(e.target.value)
              setFilteredCourses(
                courses.filter((course) =>
                  course.name
                    .toLowerCase()
                    .includes(e.target.value.toLowerCase())
                )
              )
            }}
          />
        </div>
      )}

      <Flex
        wrap='wrap'
        gap='large'
        style={{
          justifyContent: 'center',
        }}
      >
        {filteredCourses.length === 0 ? (
          <span style={{ wordBreak: 'break-all', textAlign: 'center' }}>
            {courses.length ? `No results for "${search}"` : 'No courses found'}
          </span>
        ) : (
          filteredCourses.map((course) => {
            return (
              <Class
                key={course.id}
                course={course}
                accessToken={accessToken}
              />
            )
          })
        )}
      </Flex>
    </div>
  )
}

export default GoogleClassroom
